$white-color: #ffffff;
$red-color: #ff0000;
$green-color: #00b300;
$burning-orange: #fb6d3a;
$black-squeeze: #f7fafc;
$santas-gray: #a3a3a4;
$alabaster-color: #f7f7f7;
$st-tropaz-color: #25479b;
$cinnabar-color: #e5481c;
$mine-shaft: #2d2d2d;
$whisper-color: #ededf5;
$apple-color: #419d3e;
$alto-color: #dbdbdb;
$black-color: #000000;
$sky-blue-color: #0179ff;
$california-color: #f89e07;
$medium-gray-color: #494949;
$soft-orange: #facd5d;
$scrollbar-gray-color: #7f7f7f;
$gray-shade: #7a7a7a;
$cosmic-latte: #fef7e9;
$caramel: #f6d993;
$grey-shade-two: #e9e9e9;

$success: #009944;
$danger: #cf000f;
$warning: #fed591;
$information: #63c0df;
